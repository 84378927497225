import { useTranslate } from "@lobby/ocb-intl";

import { Player } from "@entities/player";
import Tabs from "@shared/ui/tabs";

import { DepositTab, WithdrawTab } from "./components";

export function Wallet() {
  const { $t } = useTranslate();
  const { data } = Player.usePlayer();

  const isDepositActive = Boolean(data?.isDepositActive);
  const isWithdrawActive = Boolean(data?.isWithdrawActive);

  return (
    <div className="flex h-full min-h-0 flex-col">
      <Tabs
        items={[
          {
            label: $t({ defaultMessage: "Deposit" }),
            children: isDepositActive ? <DepositTab /> : null,
            disabled: !isDepositActive,
          },
          {
            label: $t({ defaultMessage: "Withdraw" }),
            children: isWithdrawActive ? (
              <WithdrawTab />
            ) : (
              $t(
                {
                  defaultMessage:
                    "Currently, there are no available methods for withdrawing funds.{br}" +
                    "Please contact the support chat to withdraw funds.",
                },
                {
                  br: <br />,
                },
              )
            ),
            // disabled: !isWithdrawActive,
          },
        ]}
      />
    </div>
  );
}
