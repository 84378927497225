import { useNavigate } from "@tanstack/react-router";
import { clsx } from "clsx";

import { LogoutButton } from "@features/player/logout-button";
import { useMobile } from "@shared/lib";
import { Button, SVGIcon } from "@shared/ui";

import type { TProfileSectionItem } from "./profile-sections";
import type { TIconName } from "@shared/ui";
import type { TProfileSection } from "@widget/profile-modal/lib";

interface IProfileSectionControlsProps {
  currentSectionId: TProfileSection | undefined;
  items: TProfileSectionItem[];
}

export function ProfileSectionControls({ items, currentSectionId }: IProfileSectionControlsProps) {
  const navigate = useNavigate();
  const isMobile = useMobile();

  return (
    <div className="lg:p-5 lg:rounded-rounded flex flex-col rounded-xs bg-athens-gray dark:bg-outer-space p-3">
      <ul className="lg:overflow-y-scroll lg:custom-scrollbar lg:h-[16.75rem] flex flex-col gap-2.5 mobile-only:max-h-max">
        {items.map(({ title, icon, id }, idx) => (
          <li key={idx} className="mr-1">
            <Button
              className={clsx(
                "lg:px-5 lg:h-15 justify-start text-base h-13 px-3 w-full whitespace-normal text-left lg:dark:active:bg-java",
                id === currentSectionId && "bg-keppel text-white dark:bg-java",
              )}
              type="primary-alternate"
              onClick={() =>
                navigate({
                  search: (prev) => ({ ...prev, profileSection: id }),
                })
              }
            >
              <div className="flex items-center">
                <SVGIcon
                  className="size-[1.1875rem] min-w-[1.1875rem] *:size-full"
                  name={icon as TIconName}
                />
                <span className="ml-2.5">{title}</span>
              </div>
            </Button>
          </li>
        ))}
      </ul>
      {!isMobile && <LogoutButton className="mt-5" />}
    </div>
  );
}
