import { clsx } from "clsx";

import logoImg from "@app/assets/logo.png";

export function Logo({ className }: { className?: string }) {
  return (
    <div className={clsx(className)}>
      <img src={logoImg} alt="playclub logo" className="max-h-full object-contain" />
    </div>
  );
}
