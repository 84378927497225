import { Description, Field, Input, Label } from "@headlessui/react";
import { useTranslate } from "@lobby/ocb-intl";
import { useState } from "react";

import { Lobby } from "@entities/lobby";
import { Player } from "@entities/player";
import { SocialNetwork } from "@entities/social-network/model/social-network.model";
import { SocialNetworkLink } from "@features/social-network-link";
import { APIError, useErrorTranslate } from "@shared/lib";
import { Button, SVGIcon } from "@shared/ui";

import { AuthEndpointsContainer, Checkbox, CurrencySelector, Separator } from "./components";

import type { FormEvent } from "react";

export function RegisterForm() {
  const [email, setEmail] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [refCode, setRefCode] = useState("");

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [refCodeExpanded, setRefCodeExpanded] = useState(false);
  const [showAgreementWarning, setShowAgreementWarning] = useState(false);
  const [validationError, setValidationError] = useState("");

  const { $t } = useTranslate();
  const { formatMessage: translateError } = useErrorTranslate();

  const { data: lobbyData } = Lobby.useLobby();
  const { mutate: registerMutate, isPending } = Player.useRegister();
  const { mutate: logInMutate } = Player.useLogIn();
  const { data: authEndpointsData } = SocialNetwork.useAuthEndpoints();

  const currencies = lobbyData?.registration.currencies ?? [];
  const authEndpoints = authEndpointsData?.result?.data ?? [];
  const isCurrenciesAvailable = currencies.length > 0;

  function handleSubmit(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    setValidationError("");

    const form = new FormData(ev.currentTarget);

    if (validateForm(form)) {
      register(form);
    }
  }

  function register(form: FormData) {
    registerMutate(
      {
        email: form.get("email") as string,
        login: form.get("login") as string,
        password: form.get("password") as string,
        refCode: (form.get("refCode") as string) || null,
        confirmMarketingMailing: form.has("marketingMailingAgreement"),
        confirmAgreement: form.has("personalDataAgreement"),
        currency: form.get("currency") as string,
      },
      {
        onSuccess: (data) => {
          const error = data.error;
          if (error) {
            const message = translateError(new APIError(error.message, { code: error.code }));
            setValidationError(message);
          } else {
            logIn(form);
          }
        },
      },
    );
  }

  function logIn(form: FormData) {
    logInMutate({
      login: form.get("login") as string,
      password: form.get("password") as string,
    });
  }

  function validateForm(form: FormData) {
    if (refCodeExpanded && !form.has("refCode")) {
      setRefCodeExpanded(false);
    }

    if (!form.has("personalDataAgreement")) {
      setShowAgreementWarning(true);
      return false;
    }

    return form.has("email") && form.has("login") && form.has("password") && validateCurrency(form);
  }

  function validateCurrency(form: FormData) {
    return !isCurrenciesAvailable || form.has("currency");
  }

  function handleAgreementChange(checked: boolean) {
    if (checked) {
      setShowAgreementWarning(false);
    }
  }

  return (
    <div className="relative text-sm lg:text-base">
      <form onSubmit={handleSubmit}>
        <Field className="flex flex-col">
          <Label className="font-bold">{$t({ defaultMessage: "E-mail" })}:</Label>
          <Input
            className="input border-1 border-shark/80"
            name="email"
            type="email"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            autoComplete="email"
            autoCapitalize="none"
            minLength={3}
            maxLength={255}
            autoFocus
            required
          />
        </Field>

        <Field className="mt-3 flex flex-col">
          <Label className="font-bold">{$t({ defaultMessage: "Login" })}:</Label>
          <Input
            className="input border-1 border-shark/80"
            name="login"
            type="text"
            value={login}
            onChange={(ev) => setLogin(ev.target.value)}
            minLength={3}
            maxLength={255}
            required
          />
        </Field>

        <Field className="mt-3 flex flex-col">
          <Label className="font-bold">{$t({ defaultMessage: "Password" })}:</Label>
          <div className="relative">
            <Input
              className="input w-full border-1 border-shark/80 pr-13"
              name="password"
              type={isPasswordVisible ? "text" : "password"}
              value={password}
              onChange={(ev) => setPassword(ev.target.value)}
              autoComplete="new-password"
              minLength={6}
              maxLength={255}
              required
            />
            <div
              className="-translate-y-1/2 absolute top-1/2 right-0 p-3 text-shark hover:cursor-pointer hover:text-shark/80 dark:text-mercury dark:hover:text-mercury/80"
              onClick={() => setIsPasswordVisible((state) => !state)}
            >
              {isPasswordVisible ? <SVGIcon name="openedEye" /> : <SVGIcon name="closedEye" />}
            </div>
          </div>
        </Field>
        {isCurrenciesAvailable && (
          <Field className="mt-3">
            <Label className="font-bold">{$t({ defaultMessage: "Currency" })}:</Label>
            <CurrencySelector currencies={currencies} />
          </Field>
        )}
        <Field className="mt-6 flex flex-col">
          {refCodeExpanded ? (
            <>
              <Label className="font-bold">{$t({ defaultMessage: "Referral code" })}:</Label>
              <Input
                className="input border-1 border-shark/80"
                name="refCode"
                type="text"
                inputMode="numeric"
                minLength={3}
                maxLength={64}
                value={refCode}
                onChange={(ev) => setRefCode(ev.target.value)}
                autoFocus
              />
            </>
          ) : (
            <Description
              className="text-center text-big-stone underline hover:cursor-pointer hover:text-steel-blue dark:text-mercury dark:hover:text-lochmara"
              onClick={() => setRefCodeExpanded(true)}
            >
              {$t({ defaultMessage: "Do you have a referral code?" })}
            </Description>
          )}
        </Field>

        <Field className="mt-3">
          <Checkbox
            name="personalDataAgreement"
            invalid={showAgreementWarning}
            onChange={handleAgreementChange}
          >
            {$t({
              defaultMessage:
                "By registering, I confirm that I am 18 years of age and I agree to the processing of personal data",
            })}
          </Checkbox>
        </Field>

        <Field className="mt-3">
          <Checkbox name="marketingMailingAgreement">
            {$t({ defaultMessage: "By registering, I agree to receive marketing materials" })}
          </Checkbox>
        </Field>

        {showAgreementWarning && (
          <p className="my-3 text-center font-medium text-radical-red text-xs uppercase lg:text-sm">
            {$t({ defaultMessage: "You must agree to the terms stated above" })}
          </p>
        )}

        <Button className="mt-5 w-full" type="primary" htmlType="submit" loading={isPending}>
          {$t({ defaultMessage: "Register me" })}
        </Button>

        {validationError && (
          <div className="my-3 text-center font-medium text-radical-red">{validationError}</div>
        )}
      </form>

      {authEndpoints.length > 0 && (
        <>
          <Separator>{$t({ defaultMessage: "or" })}</Separator>
          <AuthEndpointsContainer>
            {authEndpoints.map(({ name, stringId, url }) => (
              <SocialNetworkLink key={stringId} name={name} stringId={stringId} url={url} />
            ))}
          </AuthEndpointsContainer>
        </>
      )}
    </div>
  );
}
