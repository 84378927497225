import { useId } from "react";

export function Spin({ className }: { className?: string }) {
  const uniqId1 = useId();
  const uniqId2 = useId();

  return (
    <div className={className}>
      <svg width="100%" height="100%" viewBox="0 0 162 162">
        <linearGradient
          id={uniqId1}
          x1="74.56"
          x2="113.65"
          y1="164.85"
          y2="57.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".33" stopColor="#0487ff" />
          <stop offset=".89" stopColor="#0487ff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={uniqId2}
          x1="11.65"
          x2="31.33"
          y1="136.34"
          y2="24.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".08" stopColor="#0487ff" />
          <stop offset=".63" stopColor="#ff0403" />
        </linearGradient>
        <g>
          <path
            fill={`url(#${uniqId1})`}
            d="M153.5 72.5A8.5 8.5 0 0 0 145 81c0 35.29-28.71 64-64 64a63.8 63.8 0 0 1-46.42-20.01L22.56 137A80.75 80.75 0 0 0 81 162c44.66 0 81-36.34 81-81 0-4.7-3.8-8.5-8.5-8.5z"
          />
          <path
            fill={`url(#${uniqId2})`}
            d="M36.05 35.5 24.05 23.47A80.75 80.75 0 0 0 0 81c0 22.48 9.2 42.84 24.04 57.52l12.02-12.01A63.8 63.8 0 0 1 17 81a63.8 63.8 0 0 1 19.05-45.5z"
          />
          <path
            fill="#ff0403"
            d="M120.02 10A81.09 81.09 0 0 0 81 0a80.75 80.75 0 0 0-58.44 25L34.58 37A63.81 63.81 0 0 1 81 17a64.03 64.03 0 0 1 30.82 7.9 8.5 8.5 0 1 0 8.2-14.9z"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="
                0 81 81;
                30 81 81;
                60 81 81;
                90 81 81;
                120 81 81;
                150 81 81;
                180 81 81;
                210 81 81;
                240 81 81;
                270 81 81;
                300 81 81;
                330 81 81;
                360 81 81
                "
            dur="1s"
            repeatCount="indefinite"
          />
        </g>
      </svg>
    </div>
  );
}
